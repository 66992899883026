@import '../../styles/variables';

.root{
}

.form {
  max-width: 30rem;

  @media (min-width: 768px) {
    margin-right: 10rem;
  }
}

.hint {
  display: block;
  color: $darkGrey;
  font-size: 1.1rem;
  max-width: 22rem;
  font-style: italic;
}

@import '../../styles/variables';

.root {
  display: inline-block;
  background: $cautionYellow;
  color: darken($cautionYellow, 30%);
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 0.8rem;
  line-height: 2.5;
  padding: 0 1rem;
}

.blueBadge {
  background: skyblue;
  color: darken(skyblue, 30%);
}

.redBadge {
  background: $errorRed;
  color: darken($errorRed, 30%);
}

.greenBadge {
  background: $successGreen;
  color: darken($successGreen, 30%);
}

@import '../../styles/variables';

.root{
}

.title {
  margin-top: 4rem;
  font-size: 2rem;
}

.badge {
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.amber {
  background: $cautionYellow;
  color: darken($cautionYellow, 30%);
}

.green {
  background: $successGreen;
  color: darken($successGreen, 30%);
}

.red {
  background: $errorRed;
  color: darken($errorRed, 30%);
}

.blue {
  background: skyblue;
  color: darken(skyblue, 30%);
}

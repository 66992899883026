@import '../../styles/variables';

.root {
  margin-top: 6rem;
  max-width: 50rem;

  @media (min-width: 768px) {
    flex: 0 1 calc(50% - 1rem);
  }
}

.title {
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
  border-radius: 1rem;
  border: 0.1rem solid $darkGrey;
  padding: 1rem;
  margin-bottom: 1rem;
}

.icon {
  margin-right: 2rem;
  margin-left: 1rem;
  color: $successGreen;
  position: relative;
  display: block;

  svg {
    cursor: pointer;
    font-size: 2rem;
  }

  &::after {
    display: none;
    white-space: nowrap;
    font-size: 1.3rem;
    border-radius: 0.7rem;
    padding: 0.3rem 0.5rem;
    content: 'Mark as Resolved';
    position: absolute;
    left: 50%;
    bottom: calc(100% + 1rem);
    background: #1e1e1e;
    transform: translateX(-50%);
    color: #fff;
  }

  &:hover::after {
    display: block;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(auto);
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.wrap {
  overflow-x: auto;
  padding: 1rem 0;
}

.empty {
  background: $grey;
  border-radius: 1rem;
  padding: 4rem 3rem;
  box-sizing: content-box;
}

.emptyImage {
  width: 17rem;
  display: block;
  margin: 0 auto 3rem;
  transform: translateX(15%);
}

.emptyTitle {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.emptyText {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 0;
}

@import '../../styles/variables';

.field {
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
}

.warning {
  color: $errorRed;
  line-height: 1;
  padding: 0.75rem 0;
  margin-bottom: 0.2rem;
  font-weight: 500;

  svg {
    transform: translateY(0.1rem);
  }
}

.invalid {
}

.invalidInput {
  border-color: $errorRed !important;
  box-shadow: inset 0 0 0 0.1rem $errorRed !important;
  color: $errorRed;
}

.hint {
  display: block;
  color: $darkGrey;
  font-size: 1.1rem;
  max-width: 22rem;
  margin-top: 0.75rem;
}

@import '../../styles/variables';

.root{
}

.title {
  margin-bottom: 2rem;
}

.form {
  max-width: 30rem;

  @media (min-width: 768px) {
    margin-right: 10rem;
  }
}

.hint {
  display: block;
  color: $darkGrey;
  font-size: 1.1rem;
  max-width: 22rem;
  font-style: italic;
}

.container {
  max-width: 80rem;

  @media (min-width: 768px) {
    display: flex;
  }
}

.controls {
  align-self: flex-start;
  margin-bottom: 5rem;
  border: 0.2rem solid $grey;
  border-radius: 0.2rem;
  padding: 2rem;

  @media (min-width: 768px) {
    margin-right: 2rem;
  }
}

@import '../../styles/variables';

.root {
  margin-top: 6rem;
  max-width: 50rem;

  @media (min-width: 768px) {
    flex: 0 1 calc(50% - 1rem);
    margin-right: 2rem;
  }
}

.title {
  font-weight: bold;
}

.row {
  display: flex;
  margin-bottom: 1.8rem;

  * {
    font-size: 1.6rem;
  }
}

.date {
  color: $primaryColor;
  display: inline-block;
  margin-right: 2rem;
}

.plan {
  font-weight: bold;
}

.empty {
  background: $grey;
  border-radius: 1rem;
  padding: 4rem 3rem;
  box-sizing: content-box;
}

.emptyImage {
  width: 20rem;
  display: block;
  margin: 0 auto 3rem;
}

.emptyTitle {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.emptyText {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 0;
}

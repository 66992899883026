@import '../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.rowActions {
  display: flex;

  button {
    padding: 1.1rem 1.5rem 0.9rem;
    white-space: nowrap;
  }

  > :first-child > button {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :last-child > button {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cross,
.check {
  display: inline-block;
  margin-left: 1.1rem;
  font-size: 1.9rem;
}

.cross {
  color: $errorRed;
}

.check {
  color: $successGreen;
}

.title {
  font-weight: bold;
  margin-bottom: 0;
}

.tableWrap {
  overflow-x: auto;
  flex-grow: 1;
}

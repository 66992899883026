@import '../../styles/variables';

.root{
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.cross,
.check {
  display: inline-block;
  margin-left: 1.1rem;
  font-size: 1.9rem;
}

.cross {
  color: $errorRed;
}

.check {
  color: $successGreen;
}

.title {
  font-weight: bold;
  margin-bottom: 0;
}

.tableWrap {
  overflow-x: auto;
}

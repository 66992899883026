$primaryColor: #241242;
$secondaryColor: #db4082;
$darkGrey: #c6c6c6;
$grey: #f1f1f1;
$lightGrey: #fafafa;
$errorRed: #f77855;
$cautionYellow: #ffdd57;
$warningOrange: #f1960c;
$successGreen: #65f16d;

$zIndexBump: 10;
$zIndexHigh: 20;
$zIndexSuper: 30;

@import '../../styles/variables';

.root {
  display: flex;
  width: 100%;
}

.statRoot {
  width: calc(33% - 2rem);
  flex-basis: calc(33% - 2rem);
  text-align: center;
  max-width: 25rem;
  border: 0.1rem solid $darkGrey;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex-grow: 1;

  @media (min-width: 768px) {
    padding: 1.5rem;
    margin-right: 2rem;
    width: calc(33% - 4rem);
    flex-basis: calc(33% - 4rem);
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    padding: 2.5rem;
  }
}

.value {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.4rem;

  @media (min-width: 768px) {
    font-size: 3.1rem;
  }
}

.title {
  margin-bottom: 0;
  font-size: 1.1rem;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }
}

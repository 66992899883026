.root {
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.mobileLogo {
  display: none;
}

.authenticated {
  padding: 3rem;

  @media (min-width: 768px) {
    padding: 3rem 5rem;
  }

  .mobileLogo {
    margin: 0 auto 3rem;
    display: block;
    width: 12rem;
    max-width: 90%;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.root{
}

.name {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 4rem;
}

.details {
  max-width: none;
  line-height: 1.1;
  margin: 0;
  margin-right: 4rem;

  span {
    margin-right: 1rem;
    font-size: 2rem;
    display: block;
    margin-top: 0.75rem;

    &:nth-child(2n+1) {
      margin-top: 2rem;
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  &:first-child span:first-child {
    margin-top: 0;
  }
}

@import '../../styles/variables';

.root {
  color: $primaryColor;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 0.2rem solid $grey;

  @media (min-width: 768px) {
    width: auto;
    min-width: 24rem;
    border-top: 0;
    border-right: 0.2rem solid $grey;
    flex-direction: column;
  }
}

.logo {
  display: none;

  @media (min-width: 768px) {
    margin: 3rem 3rem 1rem;
    display: flex;

    h1 {
      font-size: 2.2rem;
      font-weight: bold;
      color: #fff;
      margin: 0;
      line-height: 1;
    }

    img {
      margin-right: 1rem;
      width: 11rem;
    }
  }
}

.links {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: column;
  }

  a {
    outline: 0;
  }
}

.link {
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.4rem;
  color: #444;
  font-weight: bold;
  position: relative;
  line-height: 2;

  span {
    display: none;

    @media (min-width: 768px) {
      display: inline-block;
    }
  }

  &:last-child {
    @media (min-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  & > svg {
    color: desaturate($primaryColor, 10%);
    font-size: 1.8rem;
    transform: translateY(22%);
    transition: transform .1s ease-in-out;
    transform-origin: center 70%;
    margin-right: 1rem;
  }

  &::before {
    content: '';
    background: #fff;
    display: block;
    position: absolute;
    width: 100%;
    height: 0.5rem;
    left: 0;
    bottom: 0;
    transition: all .2s ease-in-out;
    border-radius: 1rem 1rem 0 0;

    @media (min-width: 768px) {
      border-radius: 0 1rem 1rem 0;
      width: 0.5rem;
      height: 100%;
    }
  }

  &:hover {
    svg {
      transform: scale(1.1) translateY(22%);
    }
  }
}

.activeLink li {
  color: $primaryColor;

  &::before {
    background: $primaryColor;
  }
}

.adminText {
  font-size: 1.8rem;
  text-align: center;
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin-bottom: 4rem;
    transform: translateX(-0.5rem);
  }
}

@import '../../styles/variables';

.root {
  position: relative;
  display: inline-block;
}

.content {
  position: absolute;
  top: calc(100% + 1.5rem);
  right: 0;
  background: #fff;
  font-size: 1.2rem;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.7rem;
  padding: 1.5rem 1rem;
  z-index: 5;
  animation: showPopup .2s ease-in;
  animation-fill-mode: both;
  animation-play-state: running;
}

.arrow {
  width: 4rem;
  height: 1.6rem;
  position: absolute;
  bottom: 100%;
  right: 0;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    background: #fff;
    width: 1.6rem;
    height: 1.6rem;
    transform: translateY(50%) rotate(45deg);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
}

.item {
  line-height: 2.25;
  padding: 0 1.5rem;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: $lightGrey;
  }
}

.disabledItem {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes showPopup {
  from {
    transform: translateY(-6%);
  }

  to {
    transform: translateY(0);
  }
}

@import '../../styles/variables';

.root{
}

.container {
  max-width: 75rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.form {
  max-width: 30rem;

  @media (min-width: 768px) {
    margin-right: 10rem;
  }
}

.sidebar {
  flex-grow: 1;
}

.featuresTitle {
  font-size: 2rem;
  font-weight: normal;
}

.hint {
  display: block;
  color: $darkGrey;
  font-size: 1.1rem;
  max-width: 22rem;
  font-style: italic;
}

.editFeature {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: $grey;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {
    text-decoration: underline;
    background: darken($grey, 10%);
  }
}

.deleteFeature {
  left: 50%;

  @extend .editFeature;
}

.feature {
  border: 0.2rem $grey solid;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  svg {
    display: inline-block;
    margin-right: 1rem;
  }

  &:hover {
    .editFeature,
    .deleteFeature {
      opacity: 1;
    }
  }
}

.addFeature {
  @extend .feature;

  text-align: center;
  border-style: dashed;
  transition: all .2s ease-in-out;
  margin-bottom: 4rem;

  &:hover {
    border-color: darken($grey, 7%);
  }

  &::after {
    display: none;
  }
}

.iconPicker {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-height: 50rem;
  overflow-y: auto;
  margin-bottom: 2rem;
  grid-template-columns: repeat(3, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.iconDisplay {
  cursor: pointer;
  display: inline-block;
  border: 0.2rem solid $grey;
  border-radius: 0.8rem;
  padding: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    color: #fff;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }

  svg {
    font-size: 2rem;
  }

  &:hover {
    border-color: $darkGrey;
  }
}

.selectedIcon {
  border-color: $primaryColor;
}

.featureInput {
  padding-top: 2rem;
}

.featureInputActions {
  display: flex;
  justify-content: flex-end;
}

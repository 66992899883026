.heading {
  margin-top: 6rem;
  font-weight: bold;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.row {
  @media (min-width: 768px) {
    display: flex;
  }
}

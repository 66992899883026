@import '../../styles/variables';

.overlay {
  background: rgba(#000, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  overflow-y: auto;
  transition: opacity .19s ease-in;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  background: #fff;
  z-index: $zIndexHigh;
  overflow-y: auto;
  border-radius: 1rem;
  max-height: calc(100vh - 10rem);
  width: 90%;

  @media (min-width: 768px) {
    width: 0;
    min-width: 60rem;
    max-width: calc(100vw - 10rem);
  }
}

.inner {
  padding: 3rem 4rem;
  height: 100%;
}

.close {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 2rem;
  right: 3rem;
  font-size: 3rem;
  padding: 1rem;
  width: 5rem;
  height: 5rem;
  color: $darkGrey;
}

.actions {
  padding: 3rem 4rem;
  justify-content: flex-end;
  display: flex;

  * {
    margin-left: 2rem;
  }
}

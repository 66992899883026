@import '../../styles/variables';

$cellPadding: 1.75rem 1.5rem;

.root {
  overflow-x: auto;
  max-width: 100rem;
  width: 100%;
  position: relative;
  display: table;
  border-spacing: 0 2rem;
}

.headerRow {
  display: table-header-group;
}

.headerCell {
  box-sizing: content-box;
  color: #444;
  font-size: 1.2rem;
  text-align: left;
  padding: $cellPadding;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $grey;
}

.sortable {
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-right: 1em;

  > svg {
    width: 0.8em;
    height: 0.8em;
    position: absolute;
    right: 0;
    top: 0.2em;
  }
}

.body {
  box-shadow: none;
  position: relative;
  display: table-row-group;

  &:empty {
    &::before,
    &::after {
      position: absolute;
      text-align: center;
      display: block;
      width: 100%;
    }

    &::before {
      content: 'Add responses to get started';
      background-image: url('/no-content.png');
      background-repeat: no-repeat;
      background-size: 40rem;
      background-position: center 5rem;
      padding-top: 32rem;
      font-weight: 600;
      color: #5f5f5f;
    }

    &::after {
      content: attr(data-empty-text);
      top: 51rem;
      font-size: 1.2rem;
    }
  }
}

.empty {
  border-radius: 1rem;
  text-align: center;
  background: $grey;
}

.emptyImage {
  padding-top: 4vh;
  display: block;
  width: 16rem;
  margin: 0 auto;
  max-width: 80%;
}

.emptyTitle {
  margin-top: 3vh;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.emptyText {
  font-size: 1.3rem;
  padding-bottom: 4vh;
}

.sorted {
}

.row {
  transition: all .2s ease-in-out;
  background: transparent;
  display: table-row;

  &:hover {
    background: rgba(white, 0.85);
  }
}

.cell {
  padding: $cellPadding;
  opacity: 1;
  animation: fadeIn .2s ease-in-out;
  animation-play-state: running;
  border-top: 0.1rem solid lighten($darkGrey, 10%);
  border-bottom: 0.1rem solid lighten($darkGrey, 10%);

  &:first-child {
    border-left: 0.1rem solid lighten($darkGrey, 10%);
  }

  &:last-child {
    border-right: 0.1rem solid lighten($darkGrey, 10%);
  }
}

.headerCell,
.cell {
  vertical-align: middle;
  display: table-cell;

  &:first-child {
    border-radius: 1rem 0 0 1rem;
    padding-left: 3rem;
  }

  &:last-child {
    border-radius: 0 1rem 1rem 0;
    padding-right: 3rem;
  }
}

.inline {
  .headerCell,
  .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

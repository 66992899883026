@import '../../styles/variables';

.root{
}

.name {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 4rem;
}

.row {
  @media (min-width: 768px) {
    display: flex;
  }
}

.card {
  border: 0.1rem solid $darkGrey;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  margin-right: 1rem;

  @media (min-width: 768px) {
    padding: 1.5rem;
    margin-right: 2rem;
    max-width: 47%;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    padding: 2.5rem;
  }
}

.heading {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  font-weight: 100;
}

.subheading {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.cardValue {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
  font-weight: 100;

  &:last-child {
    margin-bottom: 0;
  }
}

.cardList {
  margin-top: 0;
  padding-left: 1.75rem;
  font-weight: 100;
  line-height: 1.5;
}

address.cardValue {
  margin-top: 1.5rem;
  font-style: normal;
  line-height: 1.4;
}

.subSubHeading {
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
}

@import '../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 4rem);
  margin: 2rem;
  // border-radius: 6rem;
  box-shadow: 0 0 0 10rem $primaryColor;
}

.logo {
  max-width: 37rem;
  padding: 0 2rem;
  width: 100%;
  margin-bottom: 4rem;
}

.container {
  flex: 0 1 auto;
  background: #fff;
  box-shadow: 0 0 1.2rem rgba(#000, 0.1);
  border-radius: 1.3rem;
  display: block;
  width: 90%;
  max-width: 37rem;
  position: relative;
  margin: 2rem;
  padding: 6rem 2rem 3rem;

  @media (min-width: 420px) {
    padding: 6rem 4rem 4rem;
  }
}

.heading {
  text-align: center;
  font-size: 2.1rem;
  font-weight: 300;
}

.icon {
  position: absolute;
  border: 0.8rem solid #fff;
  box-sizing: content-box;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  transform: translateY(-50%);
  background: $primaryColor;
  border-radius: 50%;
  padding: 1.1rem;
  font-size: 2.5rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  > * {
    stroke: #fff;
  }
}

.backLink {
  display: flex;
  align-items: center;
  line-height: 1;
  color: $primaryColor;

  svg {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.error {
  color: $errorRed;
  display: block;
  margin-bottom: 1rem;
  text-align: center;
}

@import '../../styles/variables';

.root {
  background: $grey;
  border-radius: 0.4rem;
  padding: 2rem 3rem;
  align-self: flex-start;
}

.heading {
  display: block;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}

.value,
.address > span {
  display: block;
  font-size: 1.7rem;
  font-weight: 100;
  margin-bottom: 1.5rem;
}

.address {
  font-style: normal;

  span {
    margin-bottom: 0;
    line-height: 1.4;
  }
}
